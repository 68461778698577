<template>
  <div class="main-right">
    <div class="main-title title_tbtn_alin">
      <h2>
        Salary Slip and Approval for : {{ this.month_name }} {{ this.comma }}
        {{ this.year }}
      </h2>
      <button
        class="stl-btn modal_caldn_btn"
        id="modal-tggl1"
        @click="openMonthPicker()"
      >
        <img src="@/assets/images/icon/calendar-plus.png" alt="" /> Select a
        month
      </button>
    </div>

    <div class="popup-modal" id="popupModa1" v-if="show">
      <div class="mp-content">
        <button
          class="modalOut"
          type="button"
          id="modalOutIn1"
          @click="closeMonthpicker('close')"
        >
          <img src="@/assets/images/icon/x-lg.svg" alt="" />
        </button>
        <div class="bdy-sect">
          <h3>Select a month</h3>

          <month-picker @change="showDate" no-default></month-picker>
        </div>
      </div>
    </div>

    <div class="head-bar">
      <ul>
        <li>
          Number of Employees: <span>{{ this.emplength }}</span>
        </li>
      </ul>
      <div class="fild-info">
        <form action="#">
          <div class="fr-gp">
            <!-- <input type="search" placeholder="Search..."> -->
            <input type="text" v-model="searchQuery" placeholder="Search..." />

            <!-- <button type="submit">
             <img src="../../assets/images/icon/search.svg" alt="">
           </button> -->
          </div>
        </form>
        <!-- <button class="dwnlod-btn" @click="clickedDownload()">
         download
         <img src="@/assets/images/icon/download.svg" alt="">
       </button> -->
      </div>
    </div>

    <div class="table-section" id="divToPrintAttendanceReportList">
      <div class="table-wapper">
        <div class="table-data table-w-100">
          <form action="#">
            <table
              class="payroll-table"
              cellspacing="0"
              cellpadding="0"
              border="0"
            >
              <thead>
                <tr>
                  <th>Emp ID</th>
                  <th>Emp Name</th>
                  <th>DOJ</th>
                  <th>Days Present</th>
                  <th>ML Taken</th>
                  <th>CL Taken</th>
                  <th>Max Pay</th>
                  <!-- <th>Net Pay</th>                  -->
                  <th>Generate Salary Slip</th>
                  <th>Status</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody v-for="row in resultQuery" :key="row">
                <tr>
                  <td>{{ row.employeeid }}</td>
                  <td>{{ row.name }}</td>
                  <td>{{ row.doj }}</td>
                  <td v-if="row.approved_present == null">
                    {{ row.fullday_present + row.halfday_present / 2 }}
                  </td>
                  <td v-else>{{ row.approved_present }}</td>
                  <td>{{ row.ml }}</td>
                  <td>{{ row.cl }}</td>
                  <td>{{ row.netsalary }}</td>
                  <!-- <td v-if="row.approved_present == null">{{((row.basic_salary + row.hra + row.other_allow + row.edu_allow - row.pf - row.advance)*(row.totaldays - (row.workingdays - (row.fullday_present + row.halfday_present/2 + row.ml +row.cl)))/this.daysinmonth - row.ptax).toFixed(2)}}</td>
              <td v-else>{{((row.basic_salary + row.hra + row.other_allow + row.edu_allow - row.pf - row.advance)*(row.totaldays - (row.workingdays - (row.approved_present + row.ml +row.cl)))/this.daysinmonth - row.ptax).toFixed(2)}}</td> -->
                  <td>
                    <div class="btn-lps">
                      <button
                        type="button"
                        class="edtl mx-auto"
                        id="modal-tggl1"
                        @click="isOpen(row.id)"
                      >
                        Generate
                      </button>
                    </div>
                  </td>
                  <td v-if="row.approvalstatus == 'Pending'">
                    <span class="status pendin"></span>{{ row.approvalstatus }}
                  </td>
                  <td v-if="row.approvalstatus == 'Approved'">
                    <span class="status aprvd"></span>{{ row.approvalstatus }}
                  </td>
                  <td>
                    <!-- <button type="button" class="edtl mx-auto pdf-download" @click="clickedDownload(row.id, row.name)">
                  <img src="@/assets/images/icon/download.svg" alt="">
                </button> -->
                    <button
                      type="button"
                      class="edtl mx-auto pdf-download"
                      @click="exportToPDF(row.id, row.name)"
                    >
                      <img src="@/assets/images/icon/download.svg" alt="" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>

    <div class="" id="pdfFormat">
      <table
        style="
          border-spacing: 0px;
          margin: auto;
          font-family: 'Montserrat', sans-serif;
          padding: 20px;
        "
        bgcolor="#ffffff"
        cellspacing="0"
        cellpadding="0"
        border="0"
        v-if="hidden"
      >
        <tbody>
          <tr>
            <td>
              <table
                style="
                  border-spacing: 0px;
                  font-family: 'Montserrat', sans-serif;
                  border: 1px solid #b9b9b9;
                "
                bgcolor="#ffffff"
                cellspacing="0"
                cellpadding="0"
                border="0"
              >
                <tbody>
                  <tr>
                    <td align="center">
                      <table
                        align="center"
                        style="border-spacing: 0px"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              align="center"
                              style="
                                font-size: 30px;
                                font-weight: 500;
                                color: #000;
                                padding: 20px 0 8px;
                              "
                            >
                              Reddensoft Infotech Pvt. Ltd.
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="center"
                              style="
                                font-size: 13px;
                                font-weight: 400;
                                color: #000;
                                padding: 0 0 20px;
                              "
                            >
                              Pay Slip For The Month Of {{ this.month_slip }}
                              {{ this.year_slip }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        border-top: 1px solid #b9b9b9;
                        padding: 20px;
                        vertical-align: top;
                      "
                    >
                      <table
                        style="border-spacing: 0px"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <td style="vertical-align: top">
                              <table
                                style="border-spacing: 0px"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                      "
                                    >
                                      Emp Code
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                      "
                                    >
                                      {{ empid_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                      "
                                    >
                                      Name
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                      "
                                    >
                                      {{ empname_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                      "
                                    >
                                      DOJ
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                      "
                                    >
                                      {{ doj_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                      "
                                    >
                                      Location
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                      "
                                    >
                                      {{ address_slip }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style="padding-left: 110px; vertical-align: top"
                            >
                              <table
                                style="border-spacing: 0px"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      Designations
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ position_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      Department
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ dept_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      PAN
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        vertical-align: top;
                                      "
                                    >
                                      UAN
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        vertical-align: top;
                                      "
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        border-top: 1px solid #b9b9b9;
                        padding: 15px 15px 0 15px;
                      "
                    >
                      <table
                        style="border-spacing: 0px"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <td
                              style="vertical-align: top; padding-right: 15px"
                            >
                              <table
                                style="border-spacing: 0px"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #000;
                                        padding-bottom: 10px;
                                        border-bottom: 1px solid #b9b9b9;
                                        padding-right: 15px;
                                        white-space: nowrap;
                                      "
                                    >
                                      Earning Head
                                    </th>
                                    <th
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #000;
                                        padding-bottom: 10px;
                                        border-bottom: 1px solid #b9b9b9;
                                        white-space: nowrap;
                                      "
                                    >
                                      Rate
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        padding-top: 14px;
                                        vertical-align: top;
                                      "
                                    >
                                      Basic
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        padding-top: 14px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ basic_pay_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      HRA
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ hra_pay_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      Other Allow
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ edu_allow_pay_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        vertical-align: top;
                                      "
                                    >
                                      EDU All
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ other_allow_pay_slip }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style="
                                padding-left: 15px;
                                padding-right: 15px;
                                vertical-align: top;
                                border-left: 1px solid #b9b9b9;
                              "
                            >
                              <table
                                style="border-spacing: 0px"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #000;
                                        padding-bottom: 10px;
                                        border-bottom: 1px solid #b9b9b9;
                                        padding-right: 30px;
                                        white-space: nowrap;
                                      "
                                    >
                                      Payable
                                    </th>
                                    <th
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #000;
                                        padding-bottom: 10px;
                                        border-bottom: 1px solid #b9b9b9;
                                        white-space: nowrap;
                                      "
                                    >
                                      Arrear
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        padding-top: 14px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ basic_payable_slip }}
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        padding-top: 14px;
                                        vertical-align: top;
                                      "
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ hra_payable_slip }}
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ edu_allow_payable_slip }}
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ other_allow_payable_slip }}
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        vertical-align: top;
                                      "
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style="
                                padding-left: 15px;
                                padding-right: 15px;
                                vertical-align: top;
                                border-left: 1px solid #b9b9b9;
                              "
                            >
                              <table
                                style="border-spacing: 0px"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #000;
                                        padding-bottom: 10px;
                                        border-bottom: 1px solid #b9b9b9;
                                        padding-right: 30px;
                                        white-space: nowrap;
                                      "
                                    >
                                      Deduction Head
                                    </th>
                                    <th
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #000;
                                        padding-bottom: 10px;
                                        border-bottom: 1px solid #b9b9b9;
                                        white-space: nowrap;
                                      "
                                    >
                                      Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        padding-top: 14px;
                                      "
                                    >
                                      PF
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        padding-top: 14px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ pf_payable_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      P.Tax
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                      "
                                    >
                                      {{ ptax_payable_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      Advance
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        padding-bottom: 15px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ advance_payable_slip }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        vertical-align: top;
                                      "
                                    ></td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        vertical-align: top;
                                      "
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style="
                                padding-left: 15px;
                                padding-right: 15px;
                                vertical-align: top;
                                border-left: 1px solid #b9b9b9;
                              "
                            >
                              <table
                                style="border-spacing: 0px"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tbody>
                                  <tr style="vertical-align: top">
                                    <td style="padding-bottom: 10px">
                                      <table
                                        style="border-spacing: 0px"
                                        cellspacing="0"
                                        cellpadding="0"
                                        border="0"
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              align="left"
                                              style="
                                                font-size: 13px;
                                                font-weight: 500;
                                                color: #000;
                                                padding-bottom: 10px;
                                                border-bottom: 1px solid #b9b9b9;
                                                white-space: nowrap;
                                              "
                                            >
                                              Attendance Details
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                font-size: 13px;
                                                font-weight: 400;
                                                color: #000;
                                                padding-right: 20px;
                                                padding-bottom: 10px;
                                                padding-top: 14px;
                                                vertical-align: top;
                                              "
                                            >
                                              Month Day
                                            </td>
                                            <td
                                              align="right"
                                              style="
                                                font-size: 13px;
                                                font-weight: 400;
                                                color: #303030;
                                                padding-bottom: 10px;
                                                padding-top: 14px;
                                                vertical-align: top;
                                              "
                                            >
                                              {{ total_days_salaryslip }}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                font-size: 13px;
                                                font-weight: 400;
                                                color: #000;
                                                padding-right: 20px;
                                                padding-bottom: 10px;
                                                vertical-align: top;
                                              "
                                            >
                                              LWP/Absent
                                            </td>
                                            <td
                                              align="right"
                                              style="
                                                font-size: 13px;
                                                font-weight: 400;
                                                color: #303030;
                                                padding-bottom: 10px;
                                                vertical-align: top;
                                              "
                                            >
                                              {{ lossofpay_salaryslip }}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                font-size: 13px;
                                                font-weight: 400;
                                                color: #000;
                                                padding-right: 20px;
                                                vertical-align: top;
                                              "
                                            >
                                              Days Paid
                                            </td>
                                            <td
                                              align="right"
                                              style="
                                                font-size: 13px;
                                                font-weight: 400;
                                                color: #303030;
                                                vertical-align: top;
                                              "
                                            >
                                              {{ paiddays_salaryslip }}
                                            </td>
                                            <!-- <td align="right" style="font-size: 13px;font-weight: 400;color: #303030;vertical-align: top;">{{ this.paiddays_slip - ((this.salaryslipmlcount + this.salaryslipclcount + this.dayspresent) - (presentcount_slip + cl_slip + ml_slip))}}</td> -->
                                            <!-- <td align="right" style="font-size: 13px;font-weight: 400;color: #303030;vertical-align: top;">{{ this.daysinmonth - (this.working_days_slip - (presentcount_slip+ + cl_slip + ml_slip))}}</td> -->
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      style="
                                        padding-bottom: 10px;
                                        padding-top: 20px;
                                      "
                                    >
                                      <table
                                        style="border-spacing: 0px"
                                        cellspacing="0"
                                        cellpadding="0"
                                        border="0"
                                      >
                                        <thead>
                                          <tr>
                                            <th
                                              align="left"
                                              style="
                                                font-size: 13px;
                                                font-weight: 500;
                                                color: #000;
                                                padding-bottom: 10px;
                                                border-bottom: 1px solid #b9b9b9;
                                                vertical-align: top;
                                                white-space: nowrap;
                                              "
                                            >
                                              Payment Details
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                font-size: 13px;
                                                font-weight: 400;
                                                color: #000;
                                                padding-right: 20px;
                                                padding-bottom: 10px;
                                                padding-top: 14px;
                                                vertical-align: top;
                                              "
                                            >
                                              Bank
                                            </td>
                                            <td
                                              align="right"
                                              style="
                                                font-size: 13px;
                                                font-weight: 400;
                                                color: #303030;
                                                padding-bottom: 10px;
                                                padding-top: 14px;
                                                vertical-align: top;
                                              "
                                            >
                                              Kotak Mahindra Bank
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              align="left"
                                              style="
                                                font-size: 13px;
                                                font-weight: 400;
                                                color: #000;
                                                padding-right: 20px;
                                                padding-bottom: 10px;
                                                vertical-align: top;
                                              "
                                            >
                                              Account No
                                            </td>
                                            <td
                                              align="right"
                                              style="
                                                font-size: 13px;
                                                font-weight: 400;
                                                color: #303030;
                                                padding-bottom: 10px;
                                                vertical-align: top;
                                              "
                                            >
                                              0000000000
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td
                              style="
                                border-top: 1px solid #b9b9b9;
                                padding: 15px 15px 15px 0;
                              "
                            >
                              <table
                                style="border-spacing: 0px"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        vertical-align: top;
                                      "
                                    >
                                      Total Rs.
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ totalhead_slip_payable }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style="
                                border-top: 1px solid #b9b9b9;
                                padding: 15px;
                                border-left: 1px solid #b9b9b9;
                              "
                            >
                              <table
                                style="border-spacing: 0px"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ payablehead_slip_payable }}
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        vertical-align: top;
                                      "
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style="
                                border-top: 1px solid #b9b9b9;
                                padding: 15px;
                                border-left: 1px solid #b9b9b9;
                              "
                            >
                              <table
                                style="border-spacing: 0px"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        vertical-align: top;
                                      "
                                    >
                                      Total Rs.
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ deductionhead_slip_payable }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              style="
                                border-top: 1px solid #b9b9b9;
                                padding: 15px;
                                border-left: 1px solid #b9b9b9;
                              "
                            >
                              <table
                                style="border-spacing: 0px"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style="
                                        font-size: 13px;
                                        font-weight: 400;
                                        color: #000;
                                        padding-right: 20px;
                                        vertical-align: top;
                                        white-space: nowrap;
                                      "
                                    >
                                      Net Salary Payable
                                    </td>
                                    <td
                                      align="right"
                                      style="
                                        font-size: 13px;
                                        font-weight: 500;
                                        color: #303030;
                                        vertical-align: top;
                                      "
                                    >
                                      {{ netpayhead_slip_payable }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        border-top: 1px solid #b9b9b9;
                        padding: 15px;
                        font-size: 13px;
                        font-weight: 400;
                        color: #000;
                      "
                    >
                      {{ salaryintext_payable }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="" id="pdfFormat2">
      <h1>{{ this.month_slip }}</h1>
    </div>
  </div>
  <div class="popup-modal paial-modal" id="popupModa1" v-if="seen">
    <div class="mp-content">
      <button
        class="modalOut"
        type="button"
        id="modalOutIn1"
        @click="closeDatepicker('close')"
      >
        <img src="@/assets/images/icon/x-lg.svg" alt="" />
      </button>
      <form ref="form" name="form">
        <div class="bdy-sect lip__modal__in">
          <h3>Salary Slip and Approval</h3>
          <div class="row">
            <div class="col-lg-6">
              <div class="srp-fild">
                <div class="fild-gp">
                  <label for="">employee name</label>
                  <input
                    type="text"
                    readonly
                    v-model="state3.salarydata.empname"
                  />
                </div>
                <div class="fild-gp">
                  <label for="">employee ID</label>
                  <input
                    type="text"
                    readonly
                    v-model="state3.salarydata.employeeid"
                  />
                </div>
                <div class="fild-gp">
                  <label for="">DOJ (Date of Joining)</label>
                  <input type="text" readonly v-model="state3.salarydata.doj" />
                </div>
                <div class="fild-gp">
                  <label for="">PAN</label>
                  <input type="text" readonly v-model="state3.salarydata.pan" />
                </div>
                <!-- <div class="fild-gp">
                <label for="">Designation</label>
                <input type="text">{{this.salaryslipdata.empname}}
              </div> -->
                <div class="fild-gp">
                  <label for="">Department</label>
                  <input
                    type="text"
                    readonly
                    v-model="state3.salarydata.position"
                  />
                </div>
                <div class="fild-gp">
                  <label for="">CTC</label>
                  <input type="text" readonly v-model="state3.ctc" />
                </div>
                <div class="fild-gp">
                  <label for="">Gross Salary</label>
                  <input type="text" readonly v-model="state3.gross_payable" />
                </div>
                <div class="fild-gp">
                  <label for="">UAN (Universal account Number)</label>
                  <input type="text" readonly v-model="state3.salarydata.uan" />
                </div>
                <div class="fild-gp">
                  <label for="">ESIC Number</label>
                  <input
                    type="text"
                    readonly
                    v-model="state3.salarydata.esic_num"
                  />
                </div>
                <div class="fild-gp">
                  <label for="">Basic Salary Payable</label>
                  <input type="text" readonly v-model="state3.basic" />
                </div>
                <div class="fild-gp">
                  <label for="">HRA (House Rent Allowence) Payable</label>
                  <input type="number" readonly v-model="state3.hra" />
                </div>
                <div class="fild-gp">
                  <label for="">Conveyance allowence Payable</label>
                  <input type="number" readonly v-model="state3.edu_allow" />
                </div>
                <div class="fild-gp">
                  <label for="">Other allowence Payable</label>
                  <input type="number" readonly v-model="state3.other_allow" />
                </div>
              </div>
            </div>
            <div class="col-lg-6 border-start">
              <div class="srp-fild">
                <div class="fild-gp">
                  <label for="">Total days</label>
                  <input
                    type="number"
                    readonly
                    v-model="this.state3.totaldaysinmonth"
                  />
                </div>
                <div class="fild-gp">
                  <label for="">Total working days</label>
                  <input
                    type="number"
                    readonly
                    v-model="this.state3.workingdaysinmonth"
                  />
                </div>
                <div class="fild-gp">
                  <label for="">LOP (Loss of Pay) Days</label>
                  <input type="number" readonly v-model="state3.lossofpay" />
                </div>
                <div class="fild-gp">
                  <label for="">Paid Days</label>
                  <input type="number" readonly v-model="state3.paiddays" />
                </div>
                <div class="fild-gp">
                  <label for="">Days Present</label>
                  <input
                    type="number"
                    readonly
                    min="0"
                    v-model="state3.presentcount"
                  />
                </div>

                <div class="fild-gp">
                  <label for="">CL</label>
                  <input
                    type="number"
                    min="0"
                    readonly
                    v-model="state3.salaryclcount"
                  />
                </div>
                <div class="fild-gp">
                  <label for="">ML</label>
                  <input
                    type="number"
                    min="0"
                    readonly
                    v-model="state3.salarymlcount"
                  />
                </div>
                <div class="fild-gp">
                  <label for="">PF Payabale</label>
                  <input type="number" readonly v-model="state3.pfpayable" />
                </div>
                <div class="fild-gp">
                  <label for="">ESI Payable</label>
                  <input type="number" readonly v-model="state3.esi" />
                </div>
                <!-- <div class="fild-gp">
                <label for="">Health Insurance/ESI</label>
                <input type="number">
              </div> -->
                <div class="fild-gp">
                  <label for="">Professional Tax</label>
                  <input type="number" readonly v-model="state3.ptax" />
                </div>
                <div class="fild-gp">
                  <label for="">EPS Payabale</label>
                  <input type="number" readonly v-model="state3.eps" />
                </div>
                <div class="fild-gp">
                  <label for="">ER Payabale</label>
                  <input type="number" readonly v-model="state3.er" />
                </div>
                <div class="fild-gp">
                  <label for="">ESIC2</label>
                  <input type="number" readonly v-model="state3.esic2" />
                </div>
                <div class="fild-gp">
                  <label for="">Total deductions</label>
                  <input
                    type="number"
                    readonly
                    v-model="state3.totaldeduction"
                  />
                </div>

                <div class="fild-gp pt-3 mt-3 border-top">
                  <label for="">Net Pay</label>
                  <input type="number" readonly v-model="state3.netpay" />
                </div>
                <div class="fild-gp">
                  <label for="">Total Amount in Words</label>
                  <!-- <input type="text" > -->
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    readonly
                    v-model="state3.salaryinwords"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="btn-group">
                <!-- <button>Edit</button> -->
                <button
                  type="button"
                  @click="approvepay(this.salaryslipdata.id)"
                >
                  Approve 
                </button>
                <!-- <button type="button" @click="approvepay(this.salaryslipdata.id)">Approve</button> -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { createLocal, createSession, createStorage } from "the-storages";
import { BASE_URL } from "../../config";
import axios from "axios";
// import html2pdf from 'html2pdf.js';
// import { jsPDF } from 'jspdf';
const mirror = createLocal();
const storage = mirror._prx;

import Swal from "sweetalert2";

import { ref, computed } from "vue";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { MonthPicker } from "vue-month-picker";
import html2pdf from "html2pdf.js";
// import blobStream from 'blob-stream';
// import ModalApplyForLeave from '../UserDashboard/ModalApplyForLeave.vue'
// import { AnyMap } from '@jridgewell/trace-mapping';
// import { assertAnyTypeAnnotation } from '@babel/types';
export default {
  name: "SalarySlip",
  components: {
    MonthPicker,
  },
  setup() {
    const isOpen = ref(false);
    return { isOpen };

    const openMonthPicker = ref(false);
    return { openMonthPicker };

    const state3 = reactive({
      basic: "",
      hra: "",
      edu_allow: "",
      other_allow: "",
      pf: "",
      ptax: "",
      advance: "",
      salaryclcount: "",
      salarymlcount: "",
      presentcount: "",
      salarydata: "",
      paiddays: "",
      lossofpay: "",
      deductionhead: "",
      netsalaryhead: "",
      salaryinwords: "",
      totaldaysinmonth: "",
      workingdaysinmonth: "",
      esi: "",
      eps: "",
      er: "",
      esic2: "",
      gross_payable: "",
      pfpayable: "",
      totaldeduction: "",
      netpay: "",
      basic_full: "",
      hra_full: "",
      edu_allow_full: "",
      other_allow_full: "",
      totalpayabale: "",
      companydeduction: "",
      pan: "",
      uan: "",
      esic_num: "",
    });
    const leavedate = ref();
    //return { v$: useVuelidate() };
    return {
      state3,
    };
  },
  data() {
    return {
      storageData: mirror,
      storage: storage,
      seen: false,
      show: false,
      //  hidden : false,

      leavedate: null,
      AttendanceData: {},
      attendance_details: [],
      working_days: "",
      searchQuery: null,
      year: "",
      month: "",
      today_date: "",
      selected_year: "",
      monthName: "",
      month_name_array: "",
      comma: "  ",
      AttendanceDetailsData: {},
      holiday_list: "",
      holiday_list_toDate: [],
      HolidayData: {},
      holiday_array: [],
      no_of_holidays: "",
      daysinmonth: "",
      state3: {},
      empdata: "",
      salaryslipdata: "",
      salaryslipclcount: "",
      salaryslipmlcount: "",
      salaryslipfulldaycount: "",
      salarysliphalfdaycount: "",
      approvedstatus: "",
      checkapprovedstatus: "",
      empname: "",
      empid: "",
      doj: "",
      position: "",
      basic_slip: "",
      hra_slip: "",
      edu_allow_slip: "",
      other_allow_slip: "",
      cl_slip: "",
      ml_slip: "",
      lossofpay_slip: "",
      paiddays_slip: "",
      presentcount_slip: "",
      pf: "",
      esi: "",
      ptax: "",
      advance: "",
      salaryintext: "",
      salaryslippdf: "",
      checkstatus: "",
      month_name: "",
      dayspresent: "",
      working_days_slip: "",
      total_days_slip: "",
      basic_payable: "",
      hra_payable: "",
      other_allow_payable: "",
      pf_payable: "",
      esi_payable: "",
      ptax_payable: "",
      advance_payable: "",
      edu_allow_slip_payable: "",
      totalhead_slip: "",
      payablehead_slip: "",
      deductionhead_slip: "",
      netpayhead_slip: "",
      monthnumber: "",

      month_slip: "",
      year_slip: "",
      empid_slip: "",
      empname_slip: "",
      doj_slip: "",
      address_slip: "",
      position_slip: "",
      dept_slip: "",
      basic_pay_slip: "",
      hra_pay_slip: "",
      edu_allow_pay_slip: "",
      other_allow_pay_slip: "",
      basic_payable_slip: "",
      hra_payable_slip: "",
      other_allow_payable_slip: "",
      edu_allow_payable_slip: "",
      pf_payable_slip: "",
      ptax_payable_slip: "",
      advance_payable_slip: "",
      totalhead_slip_payable: "",
      payablehead_slip_payable: "",
      deductionhead_slip_payable: "",
      total_days_salaryslip: "",
      lossofpay_salaryslip: "",
      paiddays_salaryslip: "",
      netpayhead_slip_payable: "",
      salaryintext_payable: "",
      cl_slip_payable: "",
      ml_slip_payable: "",
      salarydata: "",
      emplength: "",
      department: "",
      selectedFile: "",
      month_selected: "",
      year_selected: "",
      emparray: [],
      pan: "",
      uan: "",
      esic_num: "",

      date: {
        from: null,
        to: null,
        month: null,
        year: null,
      },
    };
  },
  async created(event) {
    await axios
      .post(BASE_URL + "/api/getempdetailsforsalarycalculation",{id:"8637526323"})
      .then((response) => {
        this.empdata = response.data.data.employeeList;
        // this.emparray = this.empdata.Handler.Object.Target;
        this.emplength = response.data.data.key;
        this.emparray = Object.values(this.empdata);
        console.log("this.emparray", this.emparray);
        this.holiday_list = response.data.holiday_list;
        console.log("response", response.data.holiday_list);

        // Convert the Proxy object to an array
      });

    var d = new Date();

    const month_name_array = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // this.month_name = month_name_array[d.getMonth()-1];
    // console.log("trtd5",d.getMonth());
    if (d.getMonth() == 0) {
      this.year = d.getFullYear() - 1;
      this.monthnumber = 12;
      this.month_name = month_name_array[11];
    } else {
      this.year = d.getFullYear();
      this.monthnumber = d.getMonth();

      this.month_name = month_name_array[d.getMonth() - 1];
    }

    var currenmonth = d.getMonth();
    // console.log("currenmonth",currenmonth);
    if (currenmonth == 0) {
      this.daysinmonth = 31;
    } else {
      this.daysinmonth = new Date(d.getFullYear(), d.getMonth(), 0).getDate();
    }

    var weekend = new Array();

    for (var i = 1; i <= this.daysinmonth; i++) {
      //looping through days in month
      if (currenmonth == 0) {
        var newDate = new Date(d.getFullYear() - 1, 11, i);
      } else {
        var newDate = new Date(d.getFullYear(), d.getMonth() - 1, i);
      }

      if (newDate.getDay() == 0) {
        //if Sunday
        weekend.push(i.toString());
        //  console.log("sun",sun);
      }
      if (newDate.getDay() == 6) {
        //if Saturday
        weekend.push(i.toString());
        //  console.log("sat",sat);
      }
    }
    var weekendstrinified = JSON.stringify(weekend);
    console.log("weekend check", weekend);

    var holidayweekend = new Array();
    var holiday_array = Object.values(this.holiday_list);

    console.log("holiday_array_string", holiday_array);

    holidayweekend = holiday_array.filter((el) => !weekend.includes(el));

    console.log("holiday weekend check", holidayweekend);
    console.log("this.holiday_list", this.holiday_list);

    this.working_days_monthly =
      this.daysinmonth - weekend.length - holidayweekend.length;
    console.log("working_days_monthly", this.working_days_monthly);
  },
  methods: {
    //   async clickedDownload(id, name){
    //     // this.hidden = true;
    //     var split_name = name.split(" ");

    //     await axios.post(BASE_URL + '/api/getsalaryslipdetails',{id:id})
    //               .then((response)=>{
    //           // this.salaryslipdata = response.data.salaryslipdetails;
    //           // this.salaryslipclcount= response.data.clcountforsalaryslip;
    //           // this.salaryslipmlcount = response.data.mlcountforsalaryslip;
    //           // this.salaryslipfulldaycount = response.data.fulldaycount;
    //           // this.salarysliphalfdaycount = response.data.halfdaycount;
    //           this.checkapprovedstatus = response.data.success;
    //           console.log("response",this.checkapprovedstatus);
    //           })
    //     // console.log("okay");
    //     if (this.checkapprovedstatus == true) {
    //       var image;
    //       await axios.post(BASE_URL + '/api/downloadsalary',{id:id})
    //           .then((response)=>{
    //             image = response.data.salaryslip.pdf;
    //               console.log(response.data.salaryslip.pdf);

    //       })
    //       var pdf = new jsPDF("1", "mm", "a2");
    //       pdf.addImage(image, 'JPEG', 15, 40, 500, 2000);
    //       pdf.save(split_name[0] + '_' + split_name[1] + '-salaryslip-' + this.month_name + '-' + this.year + '.pdf');
    //       // var pdf = new jsPDF("1", "mm", "a2");
    //       // var element = document.getElementById('pdfFormat');
    //       // // console.log(element);
    //       // var width= element.style.width;
    //       // console.log("width",width);
    //       // var height = element.style.height;
    //       //     html2canvas(element,{ scale: 1, width: 2000, height: 9000 }).then(canvas => {
    //       //         console.log(canvas);
    //       //         var image = canvas.toDataURL('image/png');
    //       //         // var img = pdf.addImage(image, 'JPEG', 15, 40, width, height);

    //       //         // axios.post(BASE_URL + '/api/savesalaryslippdf',{image:image, id:id})
    //       //         // .then((response)=>{
    //       //         //   console.log("response");
    //       //         // })

    //       //         pdf.addImage(image, 'JPEG', 15, 40, width, height);
    //       //         // console.log("pdf",pdf);
    //       //         pdf.save(name + '-salaryslip' + '-' + this.month_name + '.pdf');
    //       //         // console.log("pdf",pdf);

    //       // });
    //           // alert('Downloaded successfully.')
    //         } else {
    //           alert('Please verify before downloading')
    //         }
    //       var pdf = new jsPDF('p', 'mm', [400, 980]);
    //       // console.log("empname",this.empname);
    //       // console.log("basic_slip",this.basic_slip);
    //       // console.log("hra_slip",this.hra_slip);
    //       // console.log("edu_allow_slip",this.edu_allow_slip);
    //       // console.log("other_allow_slip",this.other_allow_slip);
    //       // console.log("cl_slip",this.cl_slip);
    //       // console.log("ml_slip",this.ml_slip);
    //       // console.log("lossofpay_slip",this.lossofpay_slip);
    //       // console.log("paiddays_slip",this.paiddays_slip);
    //       // console.log("presentcount_slip",this.presentcount_slip);
    //       // console.log("pf",this.pf);
    //       // console.log("ptax",this.ptax);
    //       // console.log("advance",this.advance);

    //  },

    // async clickedDownload(id, name){

    //   // this.hidden = true;

    //   var month = 4;
    //   var year = 2023;
    //   axios.post(BASE_URL + '/api/fetchsalarydetailsforpayslip',{ id:id, month:month, year:year})
    //   .then((response)=>{
    //     console.log(response.data.salary_details);
    //     this.month_slip = response.data.salary_details.month_name;
    //     this.year_slip = response.data.salary_details.year;
    //     this.empid_slip = response.data.salary_details.empid;
    //     this.empname_slip = response.data.salary_details.empname;
    //     this.doj_slip = response.data.salary_details.doj;
    //     this.address_slip = response.data.salary_details.address;
    //     this.position_slip = response.data.salary_details.position;
    //     this.dept_slip = response.data.salary_details.dept;
    //     this.basic_pay_slip = response.data.salary_details.basic_slip;
    //     this.hra_pay_slip = response.data.salary_details.hra_slip;
    //     this.edu_allow_pay_slip = response.data.salary_details.edu_allow_slip;
    //     this.other_allow_pay_slip = response.data.salary_details.other_allow_slip;
    //     this.basic_payable_slip = response.data.salary_details.basic_payable;
    //     this.hra_payable_slip = response.data.salary_details.hra_payable;
    //     this.other_allow_payable_slip = response.data.salary_details.other_allow_payable;
    //     this.edu_allow_payable_slip = response.data.salary_details.edu_allow_slip_payable;
    //     this.pf_payable_slip = response.data.salary_details.pf_payable;
    //     this.ptax_payable_slip = response.data.salary_details.ptax_payable;
    //     this.advance_payable_slip = response.data.salary_details.advance_payable;
    //     this.totalhead_slip_payable = response.data.salary_details.totalhead_slip;
    //     this.payablehead_slip_payable = response.data.salary_details.payablehead_slip;
    //     this.deductionhead_slip_payable = response.data.salary_details.deductionhead_slip;
    //     this.total_days_salaryslip = response.data.salary_details.total_days_slip;
    //     this.lossofpay_salaryslip = response.data.salary_details.lossofpay_slip;
    //     this.paiddays_salaryslip = response.data.salary_details.paiddays_slip;
    //     this.netpayhead_slip_payable = response.data.salary_details.netpayhead_slip;
    //     this.salaryintext_payable = response.data.salary_details.salaryintext;
    //     this.cl_slip_payable = response.data.salary_details.cl_slip;
    //     this.ml_slip_payable = response.data.salary_details.ml_slip;

    //     this.salarydata = response.data.salary_details;
    //     //this.htmltopdf(this.salarydata);
    //     //var html = `<h1>${this.cl_slip_payable}</h1>`;

    //   //html2pdf(html);

    //   })

    //   console.log("dtytfd",this.cl_slip_payable);

    //   // this.hidden = false;

    // },

    closeDatepicker(event) {
      this.seen = false;
    },

    async isOpen(id) {
      console.log("testing page....");
      
      //   console.log("rr",this.submit_status);
      this.seen = true;
      await axios
        .post(BASE_URL + "/api/getsalaryslipdetails", { id: id, token:"8637526323" })
        .then((response) => {
          var dates_for_selected_month = response.data.dates_for_selected_month;

          var doj = response.data.salaryslipdetails.doj;
          this.state3.pan = response.data.salaryslipdetails.pan;
          this.state3.uan = response.data.salaryslipdetails.uan;
          this.state3.esic_num = response.data.salaryslipdetails.esic_num;
          var doj_split = doj.split("-");
          console.log("doj_split", doj);
          var doj_formatted = doj_split[2]
            .concat("-", doj_split[1])
            .concat("-", doj_split[0]);
          var doj_formatted_time = new Date(doj_formatted);
          var dates_for_selected_month_time = new Date(
            dates_for_selected_month
          );
          console.log("doj_formatted_time", doj_formatted_time);
          this.salaryslipdata = response.data.salaryslipdetails;
          this.salaryslipclcount = response.data.clcountforsalaryslip;
          this.salaryslipmlcount = response.data.mlcountforsalaryslip;
          this.salaryslipfulldaycount = response.data.fulldaycount;
          this.salarysliphalfdaycount = response.data.halfdaycount;
          //
          var basic = response.data.salaryslipdetails.basic_salary.toFixed(2);
          this.state3.salarydata = response.data.salaryslipdetails;
          var hra = response.data.salaryslipdetails.hra.toFixed(2);
          var edu_allow = response.data.salaryslipdetails.edu_allow.toFixed(2);
          var other_allow =
            response.data.salaryslipdetails.other_allow.toFixed(2);
          this.state3.basic_full = basic;
          this.state3.hra_full = hra;
          this.state3.edu_allow_full = edu_allow;
          this.state3.other_allow_full = other_allow;
          this.state3.totalpayabale = (
            parseFloat(basic) +
            parseFloat(hra) +
            parseFloat(edu_allow) +
            parseFloat(other_allow)
          ).toFixed(0);
          this.state3.pf = response.data.salaryslipdetails.pf;
          //   console.log("this.state3.pf",this.state3.pf);

          //   this.state3.esi = (response.data.salaryslipdetails.basic_salary + response.data.salaryslipdetails.hra + response.data.salaryslipdetails.other_allow)*0.75/100
          //   console.log("this.state3.esi",this.state3.esi);

          //
          //   this.state3.ptax = response.data.salaryslipdetails.ptax;
          this.state3.advance = response.data.salaryslipdetails.advance;
          this.state3.salaryclcount = parseInt(
            response.data.clcountforsalaryslip
          );
          this.state3.salarymlcount = parseInt(
            response.data.mlcountforsalaryslip
          );
          if (response.data.dayspresent == null) {
            this.state3.presentcount =
              response.data.fulldaycount + response.data.halfdaycount / 2;
          } else {
            this.state3.presentcount = response.data.dayspresent;
          }
          this.dayspresent = this.state3.presentcount;
          // this.state3.totaldaysinmonth = this.daysinmonth;
          // this.state3.workingdaysinmonth = this.working_days_monthly;

          if (
            dates_for_selected_month_time.getTime() <
            doj_formatted_time.getTime()
          ) {
            console.log("iffffffffffffffffff");
            var d = new Date();
            var currenmonth = d.getMonth();
            // console.log("currenmonth",currenmonth);
            if (currenmonth == 0) {
              this.daysinmonth = 31;
            } else {
              this.daysinmonth = new Date(
                d.getFullYear(),
                d.getMonth(),
                0
              ).getDate();
            }
            console.log("daysinmonth", this.daysinmonth);
            // console.log("daysinmonth",this.daysinmonth);

            var weekend = new Array();
            var weekdays = new Array();

            for (var i = doj_split[0]; i <= this.daysinmonth; i++) {
              if (currenmonth == 0) {
                var newDate = new Date(d.getFullYear() - 1, 11, i);
              } else {
                var newDate = new Date(d.getFullYear(), d.getMonth() - 1, i);
              }

              if (newDate.getDay() == 0) {
                //if Sunday
                weekend.push(i);
                //  console.log("sun_doj",sun);
              } else if (newDate.getDay() == 6) {
                //if Saturday
                weekend.push(i);
                //  console.log("sat_doj",sat);
              } else {
                weekdays.push(i);
              }
            }
            var weekendstrinified = JSON.stringify(weekend);
            var weekdaysstrinified = JSON.stringify(weekdays);
            console.log("weekdays check", weekdays);
            console.log("weekend check", weekend);

            var holidayweekdays = new Array();
            var holiday_afterjoining = new Array();

            holidayweekdays = this.holiday_list.filter(
              (el) => !weekendstrinified.includes(el)
            );

            for (let i = 0; i < this.holiday_list.length; i++) {
              if (
                parseInt(this.holiday_list[i]) >= parseInt(doj_split[0]) &&
                weekendstrinified.includes(this.holiday_list[i]) == false
              ) {
                holiday_afterjoining.push(this.holiday_list[i]);
              }
            }

            // console.log("holiday_afterjoining",holiday_afterjoining);
            // console.log("holidayweekdays",holidayweekdays);
            // console.log("this.holiday_list",this.holiday_list);
            // console.log("weekendstrinified",weekendstrinified);
            // var netsalaryhead = ((this.state3.basic + this.state3.hra + this.state3.edu_allow + this.state3.other_allow - this.state3.esi - (this.state3.pf))*(this.daysinmonth - doj_split[0] + 1 - (weekdays.length - holiday_afterjoining.length - (this.state3.presentcount + this.state3.salaryclcount + this.state3.salarymlcount)))/this.daysinmonth).toFixed(2);
            // console.log("netsalary",netsalaryhead);

            // if (netsalaryhead<=10000) {
            //     this.state3.ptax = 0;
            // }
            // else if(10000<netsalaryhead && netsalaryhead<=15000){
            //     this.state3.ptax = 110;
            //     console.log("elseeeee iffffffff");
            // }
            // else if(15000<netsalaryhead && netsalaryhead<=25000){
            //     this.state3.ptax = 130;
            // }
            // else if(25000<netsalaryhead && netsalaryhead<=40000){
            //     this.state3.ptax = 150;
            //     console.log("else else ifff");
            // }
            // else if(netsalaryhead>40000){
            //     this.state3.ptax = 200;

            // }
            console.log("ptax", this.state3.ptax);
            console.log("this.state3.presentcount",this.state3.presentcount);
            console.log("this.state3.salaryclcount",this.state3.salaryclcount);
            console.log("weekend.length",weekend.length);
            console.log("holiday_afterjoining.length",holiday_afterjoining.length);
            
            this.state3.paiddays =
              this.state3.presentcount +
              this.state3.salaryclcount +
              this.state3.salarymlcount +
              weekend.length +
              holiday_afterjoining.length;
            this.state3.lossofpay =
              this.daysinmonth - doj_split[0] + 1 - this.state3.paiddays;
            this.state3.totaldaysinmonth = this.daysinmonth - doj_split[0] + 1;
            this.state3.workingdaysinmonth =
              weekdays.length - holiday_afterjoining.length;
            console.log("wdays", this.state3.workingdaysinmonth);

            this.state3.basic = (
              (basic *
                (this.state3.totaldaysinmonth -
                  (this.state3.workingdaysinmonth -
                    (this.state3.presentcount +
                      this.state3.salaryclcount +
                      this.state3.salarymlcount)))) /
              this.daysinmonth
            ).toFixed(0);
            this.state3.hra = (
              (hra *
                (this.state3.totaldaysinmonth -
                  (this.state3.workingdaysinmonth -
                    (this.state3.presentcount +
                      this.state3.salaryclcount +
                      this.state3.salarymlcount)))) /
              this.daysinmonth
            ).toFixed(0);
            this.state3.edu_allow = (
              (edu_allow *
                (this.state3.totaldaysinmonth -
                  (this.state3.workingdaysinmonth -
                    (this.state3.presentcount +
                      this.state3.salaryclcount +
                      this.state3.salarymlcount)))) /
              this.daysinmonth
            ).toFixed(0);
            this.state3.other_allow = (
              (other_allow *
                (this.state3.totaldaysinmonth -
                  (this.state3.workingdaysinmonth -
                    (this.state3.presentcount +
                      this.state3.salaryclcount +
                      this.state3.salarymlcount)))) /
              this.daysinmonth
            ).toFixed(0);
            this.state3.gross_payable = (
              parseFloat(this.state3.basic) +
              parseFloat(this.state3.hra) +
              parseFloat(this.state3.edu_allow) +
              parseFloat(this.state3.other_allow)
            ).toFixed(0);

            console.log("jhvdtedfysy if", this.state3.gross_payable);

            if (this.state3.gross_payable <= 10000) {
              this.state3.ptax = 0;
            } else if (
              10000 < this.state3.gross_payable &&
              this.state3.gross_payable <= 15000
            ) {
              this.state3.ptax = 110;
              console.log("elseeeee iffffffff");
            } else if (
              15000 < this.state3.gross_payable &&
              this.state3.gross_payable <= 25000
            ) {
              this.state3.ptax = 130;
            } else if (
              25000 < this.state3.gross_payable &&
              this.state3.gross_payable <= 40000
            ) {
              this.state3.ptax = 150;
              console.log("else else ifff");
            } else if (this.state3.gross_payable > 40000) {
              this.state3.ptax = 200;
            }

            if (
              parseFloat(this.state3.basic) +
                parseFloat(this.state3.edu_allow) +
                parseFloat(this.state3.other_allow) <=
              15000
            ) {
              this.state3.pfpayable = (
                (parseFloat(this.state3.basic) +
                  parseFloat(this.state3.edu_allow) +
                  parseFloat(this.state3.other_allow)) *
                (this.state3.pf / 100)
              ).toFixed(0);
            } else if (this.state3.pf == 0) {
              this.state3.pfpayable = 0;
            } else {
              this.state3.pfpayable = 1800;
            }

            this.state3.eps = ((this.state3.pfpayable * 8.33) / 12).toFixed(0);
            this.state3.er = (this.state3.pfpayable - this.state3.eps).toFixed(
              0
            );

            if (
              parseFloat(basic) +
                parseFloat(edu_allow) +
                parseFloat(other_allow) +
                parseFloat(hra) <=
              21000
            ) {
              this.state3.esi = (
                ((parseFloat(this.state3.basic) +
                  parseFloat(this.state3.hra) +
                  parseFloat(this.state3.other_allow)) *
                  0.75) /
                100
              ).toFixed(0);
            } else {
              this.state3.esi = 0;
            }

            this.state3.esic2 = ((this.state3.esi * 3.25) / 0.75).toFixed(0);
            this.state3.totaldeduction = (
              parseFloat(this.state3.pfpayable) +
              parseFloat(this.state3.esi) +
              parseFloat(this.state3.ptax)
            ).toFixed(0);
            this.state3.netpay = (
              this.state3.gross_payable - this.state3.totaldeduction
            ).toFixed(0);

            if (basic + edu_allow + other_allow > 15000) {
              var pf_ctc = 1800;
            } else {
              var pf_ctc =
                (basic + edu_allow + other_allow) * (this.state3.pf / 100);
            }
            console.log("elseeeeeeeeeeeeeeeeeeeeeessssss");
            
            // this.state3.ctc = (
            //   (parseFloat(this.state3.gross_payable) +
            //     parseFloat(this.state3.esic2)) /
            //     ((this.state3.totaldaysinmonth -
            //       (this.state3.workingdaysinmonth -
            //         (this.state3.presentcount +
            //           this.state3.salaryclcount +
            //           this.state3.salarymlcount))) /
            //       this.daysinmonth) +
            //   pf_ctc
            // ).toFixed(0);
            this.state3.ctc = response.data.salaryslipdetails.ctc
            console.log("if ctc",this.state3.gross_payable)
            console.log("if ctc",this.state3.esic2)
            console.log("if ctc",this.state3.totaldaysinmonth)
            console.log("if ctc",this.state3.workingdaysinmonth)
            console.log("if ctc ffw",response.data.salaryslipdetails.ctc)
            this.state3.companydeduction = (
              parseFloat(this.state3.eps) +
              parseFloat(this.state3.er) +
              parseFloat(this.state3.esic2)
            ).toFixed(0);
            // this.state3.deductionhead = ((this.state3.pf + this.state3.esi )*(this.daysinmonth - doj_split[0] +1 - ( weekdays.length - holiday_afterjoining.length - (this.state3.presentcount + this.state3.salaryclcount + this.state3.salarymlcount)))/this.daysinmonth + this.state3.ptax + this.state3.advance).toFixed(2);
            // this.state3.this.state3.gross_payable = ((this.state3.basic + this.state3.hra + this.state3.edu_allow + this.state3.other_allow - (this.state3.pf + this.state3.esi))*(this.daysinmonth - doj_split[0] + 1 - (weekdays.length - holiday_afterjoining.length - (this.state3.presentcount + this.state3.salaryclcount + this.state3.salarymlcount)))/this.daysinmonth - this.state3.ptax + this.state3.advance).toFixed(2);
          } else {
            console.log("elseeeeeeeeeeeeeeeeeeeeee");
            // console.log("1",parseInt(this.state3.presentcount));
            // console.log("2",parseInt(this.state3.salaryclcount));
            // console.log("3",parseInt(this.state3.salarymlcount));
            // var netsalaryhead = ((this.state3.basic + this.state3.hra + this.state3.edu_allow + this.state3.other_allow - (this.state3.pf + this.state3.esi ))*(this.daysinmonth - (this.working_days_monthly - (this.state3.presentcount + this.state3.salaryclcount + this.state3.salarymlcount)))/this.daysinmonth ).toFixed(2);
            // if (netsalaryhead<=10000) {
            //     this.state3.ptax = 0;
            // }
            // else if(10000<netsalaryhead && netsalaryhead<=15000){
            //     this.state3.ptax = 110;
            //     console.log("elseeeee iffffffff");
            // }
            // else if(15000<netsalaryhead && netsalaryhead<=25000){
            //     this.state3.ptax = 130;
            // }
            // else if(25000<netsalaryhead && netsalaryhead<=40000){
            //     this.state3.ptax = 150;
            //     console.log("else else ifff");
            // }
            // else if(netsalaryhead>40000){
            //     this.state3.ptax = 200;

            // }
            // console.log("nethead",netsalaryhead);
            // console.log("ptax",this.state3.ptax);


            console.log("this.state3.presentcount",this.state3.presentcount);
            console.log("this.state3.salaryclcount",this.state3.salaryclcount);
            console.log("this.state3.salarymlcount",this.state3.salarymlcount);
            console.log("daysinmonth",this.daysinmonth);
            console.log("this.working_days_monthly",this.working_days_monthly);

            this.state3.paiddays =
              this.state3.presentcount +
              this.state3.salaryclcount +
              this.state3.salarymlcount +
              this.daysinmonth -
              this.working_days_monthly;
            console.log("hgdfytd", this.state3.paiddays);
            this.state3.lossofpay =
              this.working_days_monthly -
              (this.state3.presentcount +
                this.state3.salaryclcount +
                this.state3.salarymlcount);
            this.state3.totaldaysinmonth = this.daysinmonth;
            this.state3.workingdaysinmonth = this.working_days_monthly;

            this.state3.basic = (
              (basic *
                (this.state3.totaldaysinmonth -
                  (this.state3.workingdaysinmonth -
                    (this.state3.presentcount +
                      this.state3.salaryclcount +
                      this.state3.salarymlcount)))) /
              this.daysinmonth
            ).toFixed(0);
            this.state3.hra = (
              (hra *
                (this.state3.totaldaysinmonth -
                  (this.state3.workingdaysinmonth -
                    (this.state3.presentcount +
                      this.state3.salaryclcount +
                      this.state3.salarymlcount)))) /
              this.daysinmonth
            ).toFixed(0);
            this.state3.edu_allow = (
              (edu_allow *
                (this.state3.totaldaysinmonth -
                  (this.state3.workingdaysinmonth -
                    (this.state3.presentcount +
                      this.state3.salaryclcount +
                      this.state3.salarymlcount)))) /
              this.daysinmonth
            ).toFixed(0);
            this.state3.other_allow = (
              (other_allow *
                (this.state3.totaldaysinmonth -
                  (this.state3.workingdaysinmonth -
                    (this.state3.presentcount +
                      this.state3.salaryclcount +
                      this.state3.salarymlcount)))) /
              this.daysinmonth
            ).toFixed(0);
            this.state3.gross_payable = (
              parseFloat(this.state3.basic) +
              parseFloat(this.state3.hra) +
              parseFloat(this.state3.edu_allow) +
              parseFloat(this.state3.other_allow)
            ).toFixed(0);

            console.log("jhvdtedfysy if", this.state3.gross_payable);

            if (this.state3.gross_payable <= 10000) {
              this.state3.ptax = 0;
            } else if (
              10000 < this.state3.gross_payable &&
              this.state3.gross_payable <= 15000
            ) {
              this.state3.ptax = 110;
              console.log("elseeeee iffffffff");
            } else if (
              15000 < this.state3.gross_payable &&
              this.state3.gross_payable <= 25000
            ) {
              this.state3.ptax = 130;
            } else if (
              25000 < this.state3.gross_payable &&
              this.state3.gross_payable <= 40000
            ) {
              this.state3.ptax = 150;
              console.log("else else ifff");
            } else if (this.state3.gross_payable > 40000) {
              this.state3.ptax = 200;
            }

            if (
              parseFloat(this.state3.basic) +
                parseFloat(this.state3.edu_allow) +
                parseFloat(this.state3.other_allow) <=
              15000
            ) {
              this.state3.pfpayable = (
                (parseFloat(this.state3.basic) +
                  parseFloat(this.state3.edu_allow) +
                  parseFloat(this.state3.other_allow)) *
                (this.state3.pf / 100)
              ).toFixed(0);
            } else if (this.state3.pf == 0) {
              this.state3.pfpayable = 0;
            } else {
              this.state3.pfpayable = 1800;
            }

            this.state3.eps = ((this.state3.pfpayable * 8.33) / 12).toFixed(0);
            this.state3.er = (this.state3.pfpayable - this.state3.eps).toFixed(
              0
            );

            if (
              parseFloat(basic) +
                parseFloat(edu_allow) +
                parseFloat(other_allow) +
                parseFloat(hra) <=
              21000
            ) {
              this.state3.esi = (
                ((parseFloat(this.state3.basic) +
                  parseFloat(this.state3.hra) +
                  parseFloat(this.state3.other_allow)) *
                  0.75) /
                100
              ).toFixed(0);
            } else {
              this.state3.esi = 0;
            }
            console.log("companydeduction",this.state3.eps);
            console.log("companydeduction",this.state3.er);
            console.log("companydeduction",this.state3.esic2);
            this.state3.esic2 = ((this.state3.esi * 3.25) / 0.75).toFixed(0);
            this.state3.totaldeduction = (
              parseFloat(this.state3.pfpayable) +
              parseFloat(this.state3.esi) +
              parseFloat(this.state3.ptax)
            ).toFixed(0);
            this.state3.netpay = (
              this.state3.gross_payable - this.state3.totaldeduction
            ).toFixed(0);
            this.state3.companydeduction = (
              parseFloat(this.state3.eps) +
              parseFloat(this.state3.er) +
              parseFloat(this.state3.esic2)
            ).toFixed(0);

            
            if (this.state3.pf == 0) {
              var pf_ctc = 0;
            } else {
              if (
                parseFloat(basic) +
                  parseFloat(edu_allow) +
                  parseFloat(other_allow) >
                15000
              ) {
                var pf_ctc = 1800;
                console.log("ififif");
              } else {
                var pf_ctc =
                  (parseFloat(basic) +
                    parseFloat(edu_allow) +
                    parseFloat(other_allow)) *
                  (this.state3.pf / 100);
                console.log("elseelseelsse");
              }
            }

            console.log("pfffffff", pf_ctc);
            this.state3.ctc = (
              (parseFloat(this.state3.gross_payable) +
                parseFloat(this.state3.esic2)) /
                ((this.state3.totaldaysinmonth -
                  (this.state3.workingdaysinmonth -
                    (this.state3.presentcount +
                      this.state3.salaryclcount +
                      this.state3.salarymlcount))) /
                  this.daysinmonth) +
              pf_ctc
            ).toFixed(0);
            console.log("this.state3.ctc", this.state3.ctc);
            // this.state3.deductionhead = ((this.state3.pf + this.state3.esi)*(this.daysinmonth - (this.working_days_monthly - (this.state3.presentcount + this.state3.salaryclcount + this.state3.salarymlcount)))/this.daysinmonth + this.state3.ptax + this.state3.advance).toFixed(2);
            // this.state3.netsalaryhead = ((this.state3.basic + this.state3.hra + this.state3.edu_allow + this.state3.other_allow - (this.state3.pf + this.state3.esi ))*(this.daysinmonth - (this.working_days_monthly - (this.state3.presentcount + this.state3.salaryclcount + this.state3.salarymlcount)))/this.daysinmonth - this.state3.ptax - this.state3.advance).toFixed(2);
          }

          // this.state3.lossofpay = this.working_days_monthly - (this.state3.presentcount + this.state3.salaryclcount + this.state3.salarymlcount);
          // this.state3.deductionhead = ((this.state3.pf + this.state3.ptax + this.state3.advance)*(this.daysinmonth - (this.working_days_monthly - (this.state3.presentcount + this.state3.salaryclcount + this.state3.salarymlcount)))/this.daysinmonth).toFixed(2);
          // this.state3.netsalaryhead = ((this.state3.basic + this.state3.hra + this.state3.edu_allow + this.state3.other_allow - (this.state3.pf + this.state3.ptax + this.state3.advance))*(this.daysinmonth - (this.working_days_monthly - (this.state3.presentcount + this.state3.salaryclcount + this.state3.salarymlcount)))/this.daysinmonth).toFixed(2);

          function frac(f) {
            return f % 1;
          }

          function convert_number(number) {
            if (number < 0 || number > 999999999) {
              return "NUMBER OUT OF RANGE!";
            }
            var Gn = Math.floor(number / 10000000); /* Crore */
            number -= Gn * 10000000;
            var kn = Math.floor(number / 100000); /* lakhs */
            number -= kn * 100000;
            var Hn = Math.floor(number / 1000); /* thousand */
            number -= Hn * 1000;
            var Dn = Math.floor(number / 100); /* Tens (deca) */
            number = number % 100; /* Ones */
            var tn = Math.floor(number / 10);
            var one = Math.floor(number % 10);
            var res = "";

            if (Gn > 0) {
              res += convert_number(Gn) + " CRORE";
            }
            if (kn > 0) {
              res += (res == "" ? "" : " ") + convert_number(kn) + " LAKH";
            }
            if (Hn > 0) {
              res += (res == "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
            }

            if (Dn) {
              res += (res == "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
            }

            var ones = Array(
              "",
              "ONE",
              "TWO",
              "THREE",
              "FOUR",
              "FIVE",
              "SIX",
              "SEVEN",
              "EIGHT",
              "NINE",
              "TEN",
              "ELEVEN",
              "TWELVE",
              "THIRTEEN",
              "FOURTEEN",
              "FIFTEEN",
              "SIXTEEN",
              "SEVENTEEN",
              "EIGHTEEN",
              "NINETEEN"
            );
            var tens = Array(
              "",
              "",
              "TWENTY",
              "THIRTY",
              "FOURTY",
              "FIFTY",
              "SIXTY",
              "SEVENTY",
              "EIGHTY",
              "NINETY"
            );

            if (tn > 0 || one > 0) {
              if (!(res == "")) {
                res += " AND ";
              }
              if (tn < 2) {
                res += ones[tn * 10 + one];
              } else {
                res += tens[tn];
                if (one > 0) {
                  res += "-" + ones[one];
                }
              }
            }

            if (res == "") {
              res = "zero";
            }
            return res;
          }

          function number2text(value) {
            var fraction = Math.round(frac(value) * 100);
            var f_text = "";

            if (fraction > 0) {
              f_text = "AND " + convert_number(fraction) + " PAISE";
            }

            return convert_number(value) + " RUPEE " + f_text + " ONLY";
          }

          var num = this.state3.netpay;
          this.state3.salaryinwords = number2text(num);
          // console.log("response",number2text(num));
        });
    },

    async openMonthPicker() {
      this.show = true;
    },

    async approvepay(id) {
      // this.hidden = true;

      console.log("approve");
      this.empname = this.state3.salarydata.empname;
      this.empid = this.state3.salarydata.employeeid;
      this.doj = this.state3.salarydata.doj;
      this.position = this.state3.salarydata.position;
      this.department = this.state3.salarydata.dept;
      this.basic_slip = this.state3.basic;
      this.hra_slip = this.state3.hra;
      this.edu_allow_slip = this.state3.edu_allow;
      this.other_allow_slip = this.state3.other_allow;
      this.cl_slip = this.state3.salaryclcount;
      this.ml_slip = this.state3.salarymlcount;
      this.lossofpay_slip =
        this.working_days_monthly -
        (this.state3.presentcount +
          this.state3.salaryclcount +
          this.state3.salarymlcount);
      console.log("testapprove", this.lossofpay_slip);
      this.paiddays_slip =
        this.state3.presentcount +
        this.state3.salaryclcount +
        this.state3.salarymlcount +
        this.daysinmonth -
        this.working_days_monthly;
      this.presentcount_slip = this.state3.presentcount;

      this.pf = this.state3.pf;
      this.esi = this.state3.esi;
      this.pan = this.state3.pan;
      this.uan = this.state3.uan;
      this.esic_num = this.state3.esic_num;
      console.log("this.esi", this.esi);
      //

      this.advance = this.state3.advance;
      this.working_days_slip = this.state3.workingdaysinmonth;
      this.total_days_slip = this.state3.totaldaysinmonth;
      //
      var netapproved = (
        ((parseFloat(this.basic_slip) +
          parseFloat(this.hra_slip) +
          parseFloat(this.edu_allow_slip) +
          parseFloat(this.other_allow_slip) -
          parseFloat(this.pf)) *
          (this.total_days_slip -
            (this.working_days_slip -
              (parseFloat(this.presentcount_slip) +
                parseFloat(this.cl_slip) +
                parseFloat(this.ml_slip))))) /
        this.daysinmonth
      ).toFixed(2);
      if (netapproved <= 10000) {
        this.ptax = 0;
      } else if (10000 < netapproved && netapproved <= 15000) {
        this.ptax = 110.0;
        console.log("elseeeee iffffffff");
      } else if (15000 < netapproved && netapproved <= 25000) {
        this.ptax = 130.0;
      } else if (25000 < netapproved && netapproved <= 40000) {
        this.ptax = 150.0;
        console.log("else else ifff");
      } else if (netapproved > 40000) {
        this.ptax = 200.0;
      }
      //   this.ptax = this.state3.ptax;

      this.basic_payable = (
        (this.basic_slip *
          (this.total_days_slip -
            (this.working_days_slip -
              (this.presentcount_slip + this.cl_slip + this.ml_slip)))) /
        this.daysinmonth
      ).toFixed(2);
      this.hra_payable = (
        (this.hra_slip *
          (this.total_days_slip -
            (this.working_days_slip -
              (this.presentcount_slip + this.cl_slip + this.ml_slip)))) /
        this.daysinmonth
      ).toFixed(2);
      this.other_allow_payable = (
        (this.other_allow_slip *
          (this.total_days_slip -
            (this.working_days_slip -
              (this.presentcount_slip + this.cl_slip + this.ml_slip)))) /
        this.daysinmonth
      ).toFixed(2);
      this.pf_payable = (
        (this.pf *
          (this.total_days_slip -
            (this.working_days_slip -
              (this.presentcount_slip + this.cl_slip + this.ml_slip)))) /
        this.daysinmonth
      ).toFixed(2);
      this.esi_payable = (
        (this.esi *
          (this.total_days_slip -
            (this.working_days_slip -
              (this.presentcount_slip + this.cl_slip + this.ml_slip)))) /
        this.daysinmonth
      ).toFixed(2);
      console.log("pf pf", this.esi_payable);
      this.ptax_payable = this.ptax;
      this.advance_payable = this.advance;
      this.edu_allow_slip_payable = (
        (this.edu_allow_slip *
          (this.total_days_slip -
            (this.working_days_slip -
              (this.presentcount_slip + this.cl_slip + this.ml_slip)))) /
        this.daysinmonth
      ).toFixed(2);
      this.totalhead_slip = (
        parseFloat(this.basic_slip) +
        parseFloat(this.hra_slip) +
        parseFloat(this.edu_allow_slip) +
        parseFloat(this.other_allow_slip)
      ).toFixed(2);
      this.payablehead_slip = (
        ((parseFloat(this.basic_slip) +
          parseFloat(this.hra_slip) +
          parseFloat(this.edu_allow_slip) +
          parseFloat(this.other_allow_slip)) *
          (this.total_days_slip -
            (this.working_days_slip -
              (parseFloat(this.presentcount_slip) +
                parseFloat(this.cl_slip) +
                parseFloat(this.ml_slip))))) /
        this.daysinmonth
      ).toFixed(2);
      this.deductionhead_slip = (
        (parseFloat(this.pf + this.esi) *
          (this.total_days_slip -
            (this.working_days_slip -
              (parseFloat(this.presentcount_slip) +
                parseFloat(this.cl_slip) +
                parseFloat(this.ml_slip))))) /
          this.daysinmonth +
        parseFloat(this.ptax) +
        parseFloat(this.advance)
      ).toFixed(2);
      this.netpayhead_slip = (
        ((parseFloat(this.basic_slip) +
          parseFloat(this.hra_slip) +
          parseFloat(this.edu_allow_slip) +
          parseFloat(this.other_allow_slip) -
          parseFloat(this.pf) -
          parseFloat(this.esi)) *
          (this.total_days_slip -
            (this.working_days_slip -
              (parseFloat(this.presentcount_slip) +
                parseFloat(this.cl_slip) +
                parseFloat(this.ml_slip))))) /
          this.daysinmonth -
        parseFloat(this.ptax) -
        parseFloat(this.advance)
      ).toFixed(2);
      console.log("netpayyyyyyyy", this.netpayhead_slip);

      var d = new Date();
      if (d.getMonth() == 0) {
        var year = d.getFullYear() - 1;
        var month = 12;
      } else {
        var year = d.getFullYear();
        var month = d.getMonth();
      }

      await axios
        .post(BASE_URL + "/api/storeapproveddetails", {
          id: id,
          cl: this.state3.salaryclcount,
          ml: this.state3.salarymlcount,
          dayspresent: this.state3.presentcount,
          year: year,
          month: month,
        })
        .then((response) => {
          console.log(response.data);
        });

      function frac(f) {
        return f % 1;
      }

      function convert_number(number) {
        if (number < 0 || number > 999999999) {
          return "NUMBER OUT OF RANGE!";
        }
        var Gn = Math.floor(number / 10000000); /* Crore */
        number -= Gn * 10000000;
        var kn = Math.floor(number / 100000); /* lakhs */
        number -= kn * 100000;
        var Hn = Math.floor(number / 1000); /* thousand */
        number -= Hn * 1000;
        var Dn = Math.floor(number / 100); /* Tens (deca) */
        number = number % 100; /* Ones */
        var tn = Math.floor(number / 10);
        var one = Math.floor(number % 10);
        var res = "";

        if (Gn > 0) {
          res += convert_number(Gn) + " CRORE";
        }
        if (kn > 0) {
          res += (res == "" ? "" : " ") + convert_number(kn) + " LAKH";
        }
        if (Hn > 0) {
          res += (res == "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
        }

        if (Dn) {
          res += (res == "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
        }

        var ones = Array(
          "",
          "ONE",
          "TWO",
          "THREE",
          "FOUR",
          "FIVE",
          "SIX",
          "SEVEN",
          "EIGHT",
          "NINE",
          "TEN",
          "ELEVEN",
          "TWELVE",
          "THIRTEEN",
          "FOURTEEN",
          "FIFTEEN",
          "SIXTEEN",
          "SEVENTEEN",
          "EIGHTEEN",
          "NINETEEN"
        );
        var tens = Array(
          "",
          "",
          "TWENTY",
          "THIRTY",
          "FOURTY",
          "FIFTY",
          "SIXTY",
          "SEVENTY",
          "EIGHTY",
          "NINETY"
        );

        if (tn > 0 || one > 0) {
          if (!(res == "")) {
            res += " AND ";
          }
          if (tn < 2) {
            res += ones[tn * 10 + one];
          } else {
            res += tens[tn];
            if (one > 0) {
              res += "-" + ones[one];
            }
          }
        }

        if (res == "") {
          res = "zero";
        }
        return res;
      }

      function number2text(value) {
        var fraction = Math.round(frac(value) * 100);
        var f_text = "";

        if (fraction > 0) {
          f_text = "AND " + convert_number(fraction) + " PAISE";
        }

        return convert_number(value) + " RUPEE " + f_text + " ONLY";
      }

      var num = (
        ((parseFloat(this.basic_slip) +
          parseFloat(this.hra_slip) +
          parseFloat(this.edu_allow_slip) +
          parseFloat(this.other_allow_slip) -
          parseFloat(this.pf) -
          parseFloat(this.ptax) -
          parseFloat(this.advance)) *
          (this.daysinmonth -
            (this.working_days_monthly -
              (parseFloat(this.presentcount_slip) +
                parseFloat(this.cl_slip) +
                parseFloat(this.ml_slip))))) /
        this.daysinmonth
      ).toFixed(2);
      this.salaryintext = number2text(this.netpayhead_slip);
      console.log(this.salaryintext);
      // this.salaryintext = this.state3.salaryinwords

      // var img = pdf.addImage(image, 'JPEG', 15, 40, width, height);

      // axios.post(BASE_URL + '/api/savesalaryslippdf',{image:image, id:id})
      // .then((response)=>{
      //   console.log("response");
      // })

      await axios
        .post(BASE_URL + "/api/approvesalaryslip", {
          id: id,
          image: this.salaryslippdf,
        })
        .then((response) => {
          this.approvedstatus = response.data.success;
          // console.log(approvedstatus);
          // this.salaryslipdata = response.data.salaryslipdetails;
          // this.salaryslipclcount= response.data.clcountforsalaryslip;
          // this.salaryslipmlcount = response.data.mlcountforsalaryslip;
          // this.salaryslipfulldaycount = response.data.fulldaycount;
          // this.salarysliphalfdaycount = response.data.halfdaycount;
          // console.log("response",this.salaryslipdata);
        });
      if (this.approvedstatus == true) {
        setTimeout(() => {
          this.seen = false;
        }, 500);
      }

      await axios
        .post(BASE_URL + "/api/getsalaryslipdetails", { id: id, token:"8637526323" })
        .then((response) => {
          this.salaryslipdata = response.data.salaryslipdetails;
          this.salaryslipclcount = response.data.clcountforsalaryslip;
          this.salaryslipmlcount = response.data.mlcountforsalaryslip;
          this.salaryslipfulldaycount = response.data.fulldaycount;
          this.salarysliphalfdaycount = response.data.halfdaycount;
          this.checkapprovedstatus = response.data.success;
          // console.log("response",this.salaryslipdata);
        });

      await axios
        .post(BASE_URL + "/api/getempdetailsforsalarycalculation",{id:"8637526323"})
        .then((response) => {
          this.empdata = response.data.data.employeeList;
          this.holiday_list = response.data.holiday_list;
          console.log("response", response.data.holiday_list);
        });

      var url =
        "https://employee.works-reddensoft.com/admin/public/assets/reddensoft_logo.svg";
      
      let urlBase64 = await this.getBase64Image(url);
      console.log("urlBase64",urlBase64);
      
      axios
        .post(BASE_URL + "/api/savesalarydataforpdfdownload", {
          id: id,
          empname: this.empname,
          empid: this.empid,
          doj: this.doj,
          position: this.position,
          dept: this.department,
          basic_slip: this.state3.basic_full,
          hra_slip: this.state3.hra_full,
          edu_allow_slip: this.state3.edu_allow_full,
          other_allow_slip: this.state3.other_allow_full,
          cl_slip: this.cl_slip,
          ml_slip: this.ml_slip,
          lossofpay_slip: this.lossofpay_slip,
          month: this.monthnumber,
          year: this.year,
          month_name: this.month_name,
          paiddays_slip: this.paiddays_slip,
          presentcount_slip: this.presentcount_slip,
          pf: this.state3.pf,
          ptax: this.state3.ptax,
          advance: this.advance,
          working_days_slip: this.working_days_slip,
          total_days_slip: this.total_days_slip,
          basic_payable: this.state3.basic,
          hra_payable: this.state3.hra,
          other_allow_payable: this.state3.other_allow,
          pf_payable: this.state3.pfpayable,
          ptax_payable: this.ptax_payable,
          advance_payable: this.advance_payable,
          edu_allow_slip_payable: this.state3.edu_allow,
          totalhead_slip: this.state3.totalpayabale,
          payablehead_slip: this.state3.gross_payable,
          deductionhead_slip: this.state3.totaldeduction,
          netpayhead_slip: this.state3.netpay,
          salaryintext: this.state3.salaryinwords,
          urlBase64: urlBase64,
          month2: month,
          year2: year,
          esi: this.state3.esi,
          eps: this.state3.eps,
          er: this.state3.er,
          esic2: this.state3.esic2,
          ctc: this.state3.ctc,
          compnaydeduction: this.state3.companydeduction,
          pan: this.state3.pan,
          uan: this.state3.uan,
          esic_num: this.state3.esic_num,
        })
        .then((response) => {
          console.log("response");
        });
      // var pdf = new jsPDF("1", "mm", "a2");
      // var element = document.getElementById('pdfFormat');
      // // console.log("element",element);
      // var width= element.style.width;
      // var height = element.style.height;
      // console.log("width",width);
      //     html2canvas(element,{ scale: 1, width: 2000, height: 9000 }).then(canvas => {
      //         var image = canvas.toDataURL('image/png');
      //         // console.log(image);

      //         // pdf.addImage(image, 'JPEG', 15, 40, width, height);

      //         axios.post(BASE_URL + '/api/savesalaryslippdf',{image:image, id:id, width:width, height:height})
      //         .then((response)=>{
      //           console.log("response");
      //         })
      //         // pdf.addImage(image, 'JPEG', 15, 40, width, height);
      //         // pdf.save('salaryslip' + '.pdf');

      // });

      // var pdf = html2pdf(document.getElementById('pdfFormat'));

      // this.hidden = false;
    },

    async toDataUrl() {
      // Fetch the image
      await fetch("https://cdn.logo.com/hotlink-ok/logo-social.png")
        .then((response) => response.blob())
        .then((blob) => {
          // Read the image data as a Data URL
          const reader = new FileReader();
          reader.onloadend = () => {
            // Convert the Data URL to base64
            const base64Data = reader.result.split(",")[1];
            console.log(base64Data);
            return base64Data;
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error("Error fetching or converting the image:", error);
        });

      //   html2pdf(content, {
      //     margin: 1,
      //     filename: "document.pdf",
      //     image: { type: "jpeg", quality: 0.98 },
      //     html2canvas: { dpi: 192, letterRendering: true },
      //     jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      //   });
    },

    async exportToPDF(id, name) {
      console.log("exportToPDF");
      
      const toDataUrl = async function (url, callback) {
        //Convert to base64
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
              resolve(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = () => {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          };
          xhr.open("GET", url);
          xhr.responseType = "blob";
          xhr.send();
        });
      };

      var url =
        "https://employee.works-reddensoft.com/admin/public/assets/reddensoft_logo.svg";

      let urlBase64 = await getBase64Image(url);

      // console.log("urlBase64", urlBase64);
      var d = new Date();
      if (this.month_selected == "") {
        if (d.getMonth() == 0) {
          var year = d.getFullYear() - 1;
          var month = 12;
        } else {
          var year = d.getFullYear();
          var month = d.getMonth();
        }
      } else {
        var month = this.month_selected;
        var year = this.year_selected;
      }
      // if (d.getMonth() == 0) {
      // var year = d.getFullYear()-1;
      // var month = 12;
      // }else{
      // var year = d.getFullYear();
      // var month = d.getMonth();
      // }

      // var month = 4;
      // var year = 2023;
      axios
        .post(BASE_URL + "/api/fetchsalarydetailsforpayslip", {
          id: id,
          month: month,
          year: year,
        })
        .then((response) => {
          console.log(response.data.salary_details);
          this.month_slip = response.data.salary_details.month_name;
          this.year_slip = response.data.salary_details.year;
          this.empid_slip = response.data.salary_details.empid;
          this.empname_slip = response.data.salary_details.empname;
          this.doj_slip = response.data.salary_details.doj;
          this.address_slip = response.data.salary_details.address;
          this.position_slip = response.data.salary_details.position;
          this.dept_slip = response.data.salary_details.dept;
          this.basic_pay_slip = response.data.salary_details.basic_slip;
          this.hra_pay_slip = response.data.salary_details.hra_slip;
          this.edu_allow_pay_slip = response.data.salary_details.edu_allow_slip;
          this.other_allow_pay_slip =
            response.data.salary_details.other_allow_slip;
          this.basic_payable_slip = response.data.salary_details.basic_payable;
          this.hra_payable_slip = response.data.salary_details.hra_payable;
          this.other_allow_payable_slip =
            response.data.salary_details.other_allow_payable;
          this.edu_allow_payable_slip =
            response.data.salary_details.edu_allow_slip_payable;
          this.pf_payable_slip = response.data.salary_details.pf_payable;
          this.ptax_payable_slip = response.data.salary_details.ptax_payable;
          this.advance_payable_slip =
            response.data.salary_details.advance_payable;
          this.totalhead_slip_payable =
            response.data.salary_details.totalhead_slip;
          this.payablehead_slip_payable =
            response.data.salary_details.payablehead_slip;
          this.deductionhead_slip_payable =
            response.data.salary_details.deductionhead_slip;
          this.total_days_salaryslip =
            response.data.salary_details.total_days_slip;
          this.lossofpay_salaryslip =
            response.data.salary_details.lossofpay_slip;
          this.paiddays_salaryslip = response.data.salary_details.paiddays_slip;
          this.netpayhead_slip_payable =
            response.data.salary_details.netpayhead_slip;
          this.salaryintext_payable = response.data.salary_details.salaryintext;
          this.cl_slip_payable = response.data.salary_details.cl_slip;
          this.ml_slip_payable = response.data.salary_details.ml_slip;
          this.esi_payable_slip = response.data.salary_details.esi_payable;

          this.salarydata = response.data.salary_details;
          //this.htmltopdf(this.salarydata);
          //var html = `<h1>${this.cl_slip_payable}</h1>`;

          //html2pdf(html);

          this.htmltopdf(this.salarydata, urlBase64);
        });
    },

    // async getBase64Image(url) {
    //   console.log("url",url);
      
    //     try {
    //         const response = await axios.get(url, { responseType: 'arraybuffer' });
    //         // Convert binary data to Base64 string
    //         const base64 = btoa(String.fromCharCode(...new Uint8Array(response.data)));
    //         return `data:image/png;base64,${base64}`;
    //     } catch (error) {
    //         console.error("Error fetching image:", error);
    //         return ''; // Return an empty string or handle the error as needed
    //     }
    // },

    async getBase64Image(url) {
      console.log("url", url);
      
      try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        // Convert binary data to Base64 string
        const base64 = btoa(String.fromCharCode(...new Uint8Array(response.data)));
        return `data:image/svg+xml;base64,${base64}`; // Specify SVG MIME type here
      } catch (error) {
        console.error("Error fetching image:", error);
        return ''; // Return an empty string or handle the error as needed
      }
    },

    async htmltopdf(res, logo) { 
      console.log("logo", logo);
      console.log("test...htmltopdf");
      const resvalue = Object.values(res);
   
      var url =
        "https://employee.works-reddensoft.com/admin/public/assets/reddensoft_logo.svg";
        const base64Logo = await this.getBase64Image(url);
      var htmldemo =
        `
        <style></style>
        <div style="width:100%;margin-left:-20px;transform: scale(0.84)" id="pdfFormat">
           
           <table width="100%" style="border-spacing:0px;margin:auto;font-family: sans-serif; padding: 0px; width:100%;" bgcolor="#ffffff"
           cellspacing="0" cellpadding="0" border="0" v-if="hidden">
                <tbody>
                    <tr>
                        <td>
                            <table style="border-spacing:0px;font-family: "Roboto", sans-serif; border: 1px solid #b9b9b9;"
                                bgcolor="#ffffff" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                    <tr>
                                        <td style="padding: 20px 20px;">
                                            <table width="100%" align="center" style="border-spacing:0px;" cellspacing="0"
                                                cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="padding-right: 10px;">
                                                            <img width="100" height="28.11"
                                                                style=" vertical-align: text-top;text-align: center;"
                                                                src="${base64Logo}" alt="">
                                                        </td>
                                                        <td align="right">
                                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 15px;font-weight: 700;color: #000;padding:0 0 4px;">
                                                                            Reddensoft Infotech Pvt. Ltd AAAAAAAAAAAAAAAAAAAAAA.
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 10px;font-weight: 400;color: #000;">
                                                                            AG block AMP Baisakhi, AG-112, Sector II, Bidhannagar, Kolkata, West Bengal 700091
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 10px;font-weight: 400;color: #000;padding:0 0 4px;">
                                                                            <strong> PAN:</strong> AAICR8901L
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" style="border-top: 1px solid #b9b9b9;padding: 10px 0;">
                                            <table align="center" style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                border="0">
                                                <tbody>
                                                    <tr>
                                                        <td align="center"
                                                            style="font-size: 16px;line-height: 31px;color: #5097a9;font-weight: 600;">
                                                            Pay Slip For The Month Of ${resvalue[33]} ${resvalue[34]}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td bgcolor="#E1F1F2"
                                            style="border-top: 1px solid #b9b9b9;padding: 20px 20px 0 20px;vertical-align: top;">
                                            <table style="border-spacing:0px; padding:0;" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="vertical-align: top; padding:0;">
                                                            <table style="border-spacing:0px; padding: 0;" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 15px;">
                                                                            Emp Code</td>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 15px 0;">
                                                                            ${resvalue[3]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 15px;">
                                                                            Name</td>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 15px 0;">
                                                                            ${resvalue[2]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px; padding-bottom: 15px;">
                                                                            DOJ</td>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 15px 0;">
                                                                            ${resvalue[4]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 20px;">
                                                                            CTC (In Rupees)</td>
                                                                        <td align="left"
                                                                        style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 15px 0;">
                                                                            ${resvalue[41]}
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style="padding: 0 0 0 110px;vertical-align: top;">
                                                            <table style="border-spacing:0px; padding: 0;" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 15px;vertical-align: top;">
                                                                            Designations</td>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                            ${resvalue[5]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 15px;vertical-align: top;">
                                                                            Department</td>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                            NA
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;padding-bottom: 15px;vertical-align: top;">
                                                                            PAN</td>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                            NA
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 600;color: #216997;padding-right:20px;vertical-align: top;padding-bottom: 20px;">
                                                                            UAN</td>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 0 0;">
                                                                            NA
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-top: 1px solid #b9b9b9; padding: 20px 0; vertical-align: text-top;">
                                            <table width="100%" style="border-spacing:0px;width: 100%;background-color:#f0f0f0;" cellspacing="0" cellpadding="0" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td style="vertical-align: top;">
                                                            <table style="border-spacing:0px;width: 100%;" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <thead>
                                                                    <tr>
                                                                        <th bgcolor="#8079A0" align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                            Earning Head
                                                                        </th>
                                                                        <th bgcolor="#8079A0" align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                            Rate
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 0;vertical-align: middle;">
                                                                            Basic</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 10px;vertical-align: top;">
                                                                            ${resvalue[6]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                            HRA</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                            ${resvalue[7]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                            Other Allow</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                            ${resvalue[9]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                            Conveyance</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 10px 0;">
                                                                            ${resvalue[8]}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style="vertical-align: top;border-left: 1px solid #b9b9b9;">
                                                            <table width="100%" style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <thead>
                                                                    <tr>
                                                                        <th colspan="2" bgcolor="#8079A0" align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                            Payable
                                                                        </th>
            
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding: 10px;padding-top: 10px;vertical-align: top;">
                                                                            ${resvalue[20]}</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 10px;vertical-align: top;">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                            ${resvalue[21]}</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                            ${resvalue[22]}</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                            ${resvalue[26]}</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 10px 0;">
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style="vertical-align: top;border-left: 1px solid #b9b9b9;">
                                                            <table width="100%" style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <thead>
                                                                    <tr>
                                                                        <th bgcolor="#8079A0" align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                            Deduction
                                                                        </th>
                                                                        <th bgcolor="#8079A0" align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                            Rs
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding: 10px;padding-top: 14px;padding-top: 14px;">
                                                                            PF</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 10px;vertical-align: top;">
                                                                            ${resvalue[23]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                            P.Tax</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;">
                                                                            ${resvalue[24]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                            ESI</td>
                                                                        <td align="right"
                                                                        style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;">
                                                                        ${resvalue[36]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                            Advance</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;vertical-align: top;">
                                                                            ${resvalue[25]}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                                        </td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 10px 0;">
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>

                                                        <td style="vertical-align: top;border-left: 1px solid #b9b9b9;">
                                                        <table width="100%" style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                            border="0">
                                                            <thead>
                                                                <tr>
                                                                    <th bgcolor="#8079A0" align="left"
                                                                        style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                        C.Deduction
                                                                    </th>
                                                                    <th bgcolor="#8079A0" align="right"
                                                                        style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                        Rs
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td align="left"
                                                                        style="font-size: 13px;font-weight: 400;color: #000;padding: 10px;padding-top: 14px;padding-top: 14px;">
                                                                        EPS </td>
                                                                    <td align="right"
                                                                        style="font-size: 13px;font-weight: 500;color: #303030;padding: 10px;vertical-align: top;">
                                                                        ${resvalue[38]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="left"
                                                                        style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                        ER</td>
                                                                    <td align="right"
                                                                        style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;">
                                                                        ${resvalue[39]}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="left"
                                                                        style="font-size: 13px;font-weight: 400;color: #000;padding:0 10px 10px;vertical-align: top;">
                                                                        ESIC2</td>
                                                                    <td align="right"
                                                                    style="font-size: 13px;font-weight: 500;color: #303030;padding: 0 10px 10px 0;">
                                                                    ${resvalue[40]}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td align="left"
                                                                        style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                                    </td>
                                                                    <td align="right"
                                                                        style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 10px 0;">
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </td>

                                                        <td style="vertical-align: top;border-left: 1px solid #b9b9b9;">
                                                            <table width="100%" style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <tbody>
                                                                    <tr style="vertical-align: top;">
                                                                        <td style="padding-bottom: 10px;">
                                                                            <table width="100%" style="border-spacing:0px;" cellspacing="0"
                                                                                cellpadding="0" border="0">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th bgcolor="#8079A0" align="left"
                                                                                            style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                                            Attendance Details
                                                                                        </th>
                                                                                        <th bgcolor="#8079A0"
                                                                                            style="font-size: 13px;font-weight: 500;color: #ffffff;padding: 10px 27px;border-bottom: 1px solid #b9b9b9;white-space: nowrap;">
                                                                                            Days
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td align="left"
                                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:10px;">
                                                                                            Month Day</td>
                                                                                        <td align="center"
                                                                                            style="font-size: 13px;font-weight: 400;color: #303030; padding: 10px 0; vertical-align: top;">
                                                                                            ${resvalue[19]}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td align="left"
                                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                                                            Monthly CL</td>
                                                                                        <td align="center"
                                                                                            style="font-size: 13px;font-weight: 400;color: #303030; padding: 10px 0;vertical-align: top;">
                                                                                            ${resvalue[10]}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td align="left"
                                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                                                            Monthly ML</td>
                                                                                        <td align="center"
                                                                                            style="font-size: 13px;font-weight: 400;color: #303030;padding: 10px 0;vertical-align: top;">
                                                                                            ${resvalue[11]}'</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td align="left"
                                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:10px;">
                                                                                            LWP/Absent</td>
                                                                                        <td align="center"
                                                                                            style="font-size: 13px;font-weight: 400;color: #303030;padding: 10px 0;vertical-align: top;">
                                                                                            ${resvalue[12]}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td align="left"
                                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;">
                                                                                            Days Paid</td>
                                                                                        <td align="center"
                                                                                            style="font-size: 13px;font-weight: 400; padding: 10px 0; color: #303030;vertical-align: top;">
                                                                                            ${resvalue[13]}</td>
                                                                                        <!-- <td align="center" style="font-size: 13px;font-weight: 400;color: #303030;vertical-align: top;">{{ this.paiddays_slip - ((this.salaryslipmlcount + this.salaryslipclcount + this.dayspresent) - (presentcount_slip + cl_slip + ml_slip))}}</td> -->
                                                                                        <!-- <td align="center" style="font-size: 13px;font-weight: 400;color: #303030;vertical-align: top;">{{ this.daysinmonth - (this.working_days_slip - (presentcount_slip+ + cl_slip + ml_slip))}}</td> -->
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
            
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td style="border-top: 1px solid #b9b9b9;padding:15px 15px 15px 0;">
                                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding: 10px 10px 10px;vertical-align: top;">
                                                                            Total Rs.</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 10px 10px 10px 0;">
                                                                            ${resvalue[27]}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td
                                                            style="border-top: 1px solid #b9b9b9;padding: 15px;border-left: 1px solid #b9b9b9;">
                                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 500;color: #000;padding:10px;vertical-align: top;">
                                                                            ${resvalue[28]}</td>
                                                                        <td align="right"
                                                                            style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 0 10px 10px 0;">
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style="border-top: 1px solid #b9b9b9;padding: 15px;border-left: 1px solid #b9b9b9;">
                                                            <table  style="border-spacing:0px;width:100%" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left" style="font-size: 13px;font-weight: 400;color: #000;padding:12px 20px 10px 10px;vertical-align: top;">
                                                                            Amount
                                                                        </td>
                                                                        <td align="right" style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 12px 0 10px 0;">
                                                                            ${resvalue[29]}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style="border-top: 1px solid #b9b9b9;padding: 15px;border-left: 1px solid #b9b9b9;">
                                                            <table  style="border-spacing:0px;width:100%" cellspacing="0" cellpadding="0"
                                                                border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left" style="font-size: 13px;font-weight: 400;color: #000;padding:12px 20px 10px 10px;vertical-align: top;">
                                                                            Amount
                                                                        </td>
                                                                        <td align="right" style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 12px 0 10px 0;">
                                                                            ${resvalue[42]}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style="border: 1px solid #b9b9b9;padding: 15px;">
                                                            <table style="border-spacing:0px;" cellspacing="0" cellpadding="0" border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="left"
                                                                            style="font-size: 13px;font-weight: 400;color: #000;padding:10px;vertical-align: top;white-space: nowrap;">
                                                                            Net Salary Payable</td>
                                                                        <td align="right" style="font-size: 13px;font-weight: 500;color: #303030;vertical-align: top;padding: 10px 10px 10px 0;">
                                                                            ${resvalue[30]}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="5"
                                                            style="border-top: 1px solid #b9b9b9;padding: 15px;font-size: 12px;font-weight: 400;color: #000;">
                                                            <span style="font-weight: 700;padding-right: 5px;">Salary In Words</span>
                                                            ${resvalue[31]}
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
       </div>`;

      // window.jsPDF = window.jspdf.jsPDF;

      // var doc = new jsPDF();

      // // Source HTMLElement or a string containing HTML.
      // var elementHTML = document.querySelector("#pdfFormat");

      // doc.html(elementHTML, {
      //     callback: function(doc) {
      //         // Save the PDF
      //         doc.save('sample-document.pdf');
      //     },
      //     x: 15,
      //     y: 15,
      //     width: 170, //target width in the PDF document
      //     windowWidth: 650 //window width in CSS pixels
      // });
      // console.log("doc",doc);

      console.log('htmldemo==================',htmldemo);
      htmldemo += ``;
      html2pdf(htmldemo, {
        filename: resvalue[2]
          .concat("-", "payslip")
          .concat("-", resvalue[33].concat("-", resvalue[34])),
      });

      //open//
      // const opt = {
      //         margin: 10,
      //         filename: 'generated_pdf.pdf',
      //         image: { type: 'jpeg', quality: 0.98 },
      //         html2canvas: { scale: 2 },
      //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      //       };
      // const pdf = await html2pdf().from(htmldemo).set(opt).outputPdf();
      // const blob = new Blob([pdf], { type: 'application/pdf' });
      // const formData = new FormData();
      // this.handleFileChange();
      // formData.append('pdfFile', blob, 'generated_pdf.pdf');
      // formData.append('file', this.selectedFile, this.selectedFile.name);
      // var pdfBlob = html2pdf().from(htmldemo).output('blob');
      // console.log("testpdf",pdfBlob);

      // const blob = new Blob([pdfBlob], { type: 'application/pdf' });

      // const formData = new FormData();
      // formData.append('pdfFile', blob, 'generated.pdf');

      axios.post(BASE_URL + "/api/savesalarypdf", formData).then((response) => {
        console.log("uyertfgeusyyr", response.data.salary_details);
      });
    },

    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      console.log("this.selectedFile", this.selectedFile);
    },

    closeMonthpicker(event) {
      this.show = false;
    },

    async showDate(date) {
      console.log("date", date);
      this.month_selected = date.monthIndex;
      this.year_selected = date.year;
    },

    read() {},
  },

  computed: {
    resultQuery() {
      if (this.searchQuery) {
        console.log("this.empdatasearch", this.empdata);
        return this.emparray.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((w) =>
              item.name.concat(item.employeeid).toLowerCase().includes(w)
            );
          //  this.searchQuery.toLowerCase().split(' ').every(v => item.leavestatus.toLowerCase().includes(v)))
        });
      } else {
        return this.empdata;
      }
    },
  },

  mounted() {
    this.read();
  },
};
</script>
